.tree-container.hidden {
  display: none;
}

.tree-container {
  display: block;
  /* padding: 10px 0; */
  height: 100%;
  overflow: auto;
}

.tree-container__item {
  padding-top: .3rem;
}

.tree-container__item input {
  margin-right: 3px;
}

.tree-container__item-children {
  padding: 0;
  padding-top: 3px;
  padding-left: 10px;
}

.webmap-tree-container {
  padding-left: 0.3rem;
}

/* .tree-container__item-children > .tree-container__item {
  padding:
} */
