.panel-header {
  padding: 0 4px;
  background-color: rgba(0, 0, 0, .05);
  border-bottom: 1px solid rgba(0, 0, 0, .4);
  height: 45px;
  line-height: 45px;
  margin-bottom: 0!important;
}

.panel-header__toggle {
  /* width: 30px; */
  /* height: 30px; */
  /* float: right; */
  /* border: 1px solid rgba(0,0,0,.7); */
  margin: 9px;
  /* border-radius: 4px; */
  /* cursor: pointer; */
  /* text-align: center; */
  /* line-height: 30px; */
}

.panel-content-padding {
  padding: 10px;
}
