.columns .column {
    padding: 5px!important;
}

.attachment {
    overflow: hidden;
    padding-top: 10px;
}

.sign-out--btn {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

.sign-out--btn svg {
    height: 100%;
}

.btn-control-icon {
    width: 100%;
    height: 100%;
}

.leaflet-pane.leaflet-overlay-pane {
    z-index: 4000;
}

.leaflet-marker-icon.polyline-measure-tooltip {
    font-weight: 800;
    width: 80px!important;
    height: auto!important;
    z-index: 4000!important;
    color: black;
}
