@import './variables.scss';

$top: $navbar-height;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body,
#container,
#map {
	width: 100%;
	height: 100%;
}

html {
	overflow: hidden;
}

#container {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	height: 100%;
}

#tree,
#map {
	height: 100%;
}

.overlap-navbar {
	position: absolute;
	z-index: 5000;
	width: 100%;
}

.dialog--input {
	margin: 5px;
}

.dialog--button {
	padding: 5px;
}

.leaflet-top,
.webmap-ctrl-top-left,
.webmap-ctrl-top-right,
.webmap-ctrl-left,
.webmap-ctrl-right {
	top: $top;
	margin-top: 0;
}

.ngw-map-container.leaflet-container {
	background: #fff;
}

.leaflet-pane.leaflet-popup-pane {
	z-index: 4500;
}

.leaflet-pane.leaflet-tooltip-pane {
	z-index: 10002;
}

.leaflet-pane.leaflet-marker-pane {
	z-index: 4030;
}

.leaflet-sidebar {
	padding-top: $top;
	padding-bottom: 0;
}

.leaflet-sidebar.left {
	padding-left: 0;
}

.webmap-ctrl-left {
	padding: 0;
}

.leaflet-sidebar > .leaflet-control {
	padding: 0;
	background-color: $navbar-background-color;
	border: none;
	border-radius: 0;
}

.leaflet-touch .leaflet-sidebar > .leaflet-control {
	border: none;
	border-radius: 0;
}

.webmap-sidebar {
	padding: 0;
	background-color: $navbar-background-color;
	border: none;
	border-radius: 0;
}

.webmap-touch .webmap-sidebar {
	border: none;
	border-radius: 0;
}

.navbar-brand .left-burger {
	align-items: flex-start;
	display: block !important;
}

.navbar-brand .left-burger.navbar-burger {
	margin-left: 0;
	margin-right: 0;
}

.polyline-measure-controlOnBgColor,
.toggle-control.active {
	background-color: $primary !important;
}

.modal {
	z-index: 5001;
}

.modal-content {
	width: 350px;
}

.modal.is-active {
	display: flex;
}

// fix measure label
.leaflet-measure-label .content {
	margin-bottom: 0 !important;
}

.calendar-control-btn {
	background: transparent;
}

